"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var TextFormatting = _interopRequireWildcard(require("@/helpers/TextFormatting"));

var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));

var _default = {
  components: {
    AwayStatusIndicator: _AwayStatusIndicator.default
  },
  props: {
    network: Object
  },
  data: function data() {
    return {
      new_nick: '',
      error_message: '',
      event_listeners: [],
      self_user_settings_open: false
    };
  },
  computed: {
    modeString: function modeString() {
      var _context;

      var str = '';
      (0, _forEach.default)(_context = this.network.ircClient.user.modes).call(_context, function (mode) {
        str += mode;
      }); // Only show the + if there are modes to show

      if (str) {
        str = '+' + str;
      }

      return str;
    },
    netUser: function netUser() {
      return this.network.ircClient.user;
    },
    awayStatus: {
      get: function get() {
        return this.network.currentUser().away;
      },
      set: function set(val) {
        this.network.ircClient.raw('AWAY', val ? 'Currently away' : '');
      }
    }
  },
  methods: {
    openSelfActions: function openSelfActions() {
      this.self_user_settings_open = true;
      this.error_message = '';
    },
    openProfile: function openProfile() {
      this.$state.$emit('userbox.show', this.network.currentUser());
    },
    closeSelfUser: function closeSelfUser() {
      this.$emit('close');
    },
    onNewNickSubmit: function onNewNickSubmit(newVal, done) {
      if (this.event_listeners.length) {
        // nick change already in progress
        return;
      }

      this.new_nick = newVal;
      this.changeNick(done);
    },
    changeNick: function changeNick(done) {
      var _context2;

      var nick = (0, _trim.default)(_context2 = this.new_nick).call(_context2);

      if (nick.length === 0) {
        this.error_message = TextFormatting.t('error_empty_nick');
        done();
        return;
      }

      if (nick.match(/(^[0-9])|(\s)/)) {
        this.error_message = TextFormatting.t('error_no_number');
        done();
        return;
      }

      if (nick === this.network.currentUser().nick) {
        this.error_message = TextFormatting.t('error_nick_in_use', {
          nick: nick
        });
        done();
        return;
      }

      this.error_message = '';
      this.listenForNickEvents(done);
      this.network.ircClient.changeNick(nick);
    },
    listenForNickEvents: function listenForNickEvents(done) {
      var _this = this;

      this.event_listeners.push(this.listen(this.network.ircClient, 'nick', function (event) {
        if (event.new_nick !== _this.network.currentUser().nick) {
          return;
        }

        _this.closeNickChange();
      }));
      this.event_listeners.push(this.listen(this.network.ircClient, 'nick in use', function (event) {
        _this.error_message = TextFormatting.t('error_nick_in_use', {
          nick: event.nick
        });

        _this.removeNickEventListeners();

        done();
      }));
      this.event_listeners.push(this.listen(this.network.ircClient, 'nick invalid', function (event) {
        _this.error_message = TextFormatting.t('error_nick_invalid', {
          nick: event.nick
        });

        _this.removeNickEventListeners();

        done();
      })); // Maybe the nickchange will result in an event we are not listening for above

      var timeout = this.setTimeout(function () {
        _this.error_message = TextFormatting.t('error_unexpected');

        _this.removeNickEventListeners();
      }, 4000);
      this.event_listeners.push(function () {
        _this.clearTimeout(timeout);
      });
    },
    removeNickEventListeners: function removeNickEventListeners() {
      while (this.event_listeners.length) {
        this.event_listeners.shift()();
      }
    },
    closeNickChange: function closeNickChange() {
      this.removeNickEventListeners();
      this.self_user_settings_open = false;
    },
    networkSupportsAway: function networkSupportsAway() {
      return this.network.ircClient.network.cap.isEnabled('away-notify');
    },
    checkUserAway: function checkUserAway() {
      return !!this.network.currentUser().away;
    },
    getUserFromString: function getUserFromString(name) {
      return this.$state.getUser(this.network.id, name);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["SelfUser"]
window._kiwi_exports.components.SelfUser = exports.default ? exports.default : exports;
