"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _lastIndexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/last-index-of"));

var _values = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/values"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/toConsumableArray"));

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _parseInt2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/parse-int"));

var _setTimeout2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/set-timeout"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));

var _create = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/create"));

var _lodash = _interopRequireDefault(require("lodash"));

var htmlparser = _interopRequireWildcard(require("htmlparser2"));

var Colours = _interopRequireWildcard(require("@/helpers/Colours"));

var Misc = _interopRequireWildcard(require("@/helpers/Misc"));

var Vue = require('vue');

var _default = Vue.component('irc-input', {
  props: ['placeholder'],
  data: function data() {
    return {
      last_known_value: '',
      text_value: '',
      current_el: null,
      current_el_pos: 0,
      default_colour: null,
      code_map: (0, _create.default)(null)
    };
  },
  computed: {
    editor: function editor() {
      return this.$refs.editor;
    }
  },
  mounted: function mounted() {
    this.resetStyles();
  },
  methods: {
    onTextInput: function onTextInput(event) {
      // Mobile devices trigger a textInput event for things such as autocompletion
      // and suggested words. Unfortunately they end with a return character which
      // is not what we expect, so prevent the original event from inserting anything
      // and manually place it in with the current word.
      if (event.data[event.data.length - 1] === '\n') {
        var _context;

        event.preventDefault();
        this.setCurrentWord((0, _trim.default)(_context = event.data).call(_context));
      }
    },
    onPaste: function onPaste(event) {
      var _this = this;

      event.preventDefault();

      if (typeof event.clipboardData !== 'undefined') {
        var _context2;

        var ignoreThisPaste = false;
        var clpData = event.clipboardData;
        (0, _forEach.default)(_context2 = clpData.types).call(_context2, function (type) {
          var ignoreTypes = ['Files', 'image'];
          (0, _forEach.default)(ignoreTypes).call(ignoreTypes, function (ig) {
            if ((0, _indexOf.default)(type).call(type, ig) > -1) {
              ignoreThisPaste = true;
            }
          });
        });

        if (ignoreThisPaste) {
          return;
        }

        document.execCommand('insertText', false, clpData.getData('text/plain'));
      } else {
        // IE11
        var clpText = window.clipboardData.getData('Text');

        if (!clpText) {
          return;
        }

        var selection = window.getSelection();
        var range = selection.getRangeAt(0);

        if (range) {
          range.deleteContents();
          range.insertNode(document.createTextNode(clpText));
        }
      }

      (0, _setTimeout2.default)(function () {
        _this.updateValueProps();
      }, 0);
    },
    onFocus: function onFocus(event) {
      // Chrome sometimes focus' the element but does not add the cursor
      // https://bugs.chromium.org/p/chromium/issues/detail?id=1125078
      this.focus(); // when the input is empty there are no children to remember the current colour
      // so upon regaining focus we must set the current colour again

      if (!this.getRawText() && this.default_colour) {
        this.setColour(this.default_colour.code, this.default_colour.colour);
      }

      this.$emit('focus', event);
    },
    updateValueProps: function updateValueProps() {
      var selection = window.getSelection();

      if (selection.rangeCount === 0) {
        return;
      }

      this.current_el_pos = selection.anchorOffset;
      this.current_el = selection.anchorNode;
      this.maybeEmitInput();
    },
    selectionToEnd: function selectionToEnd() {
      // Move the caret to the end
      var el = this.$refs.editor.lastChild || this.$refs.editor;
      this.current_el = el;

      if (el.nodeType === 3) {
        this.current_el_pos = el.length;
      } else {
        document.execCommand('selectAll', false, null);
        document.getSelection().collapseToEnd();
        this.updateValueProps();
      }
    },
    setValue: function setValue(newVal) {
      this.value = newVal;
      this.$refs.editor.innerHTML = newVal;
    },
    getValue: function getValue() {
      return this.$refs.editor.innerHTML;
    },
    maybeEmitInput: function maybeEmitInput() {
      var currentHtml = this.$refs.editor.innerHTML;

      if (this.last_known_value !== currentHtml) {
        this.$emit('input', currentHtml);
        this.last_known_value = currentHtml;
      }
    },
    buildIrcText: function buildIrcText() {
      var _this2 = this,
          _context6;

      var source = this.$refs.editor.innerHTML;
      var textValue = ''; // Toggles are IRC style and colour codes that should be reset at the end of
      // the current tag

      var toggles = [];

      function addToggle(t) {
        toggles[toggles.length - 1] += t;
      }

      function getToggles() {
        return toggles[toggles.length - 1];
      }

      var parser = new htmlparser.Parser({
        onopentag: function onopentag(name, attribs) {
          toggles.push('');
          var codeLookup = '';

          if (attribs.style) {
            var _context3, _context4, _context5;

            var match = attribs.style.match(/color: ([^;]+)/);

            if (match) {
              codeLookup = match[1];
              var mappedCode = _this2.code_map[codeLookup];

              if (!mappedCode) {
                // If we didn't have an IRC code for this colour, convert the
                // colour to its hex form and check if we have that instead
                var m = codeLookup.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

                if (m) {
                  var hex = Colours.rgb2hex({
                    r: (0, _parseInt2.default)(m[1], 10),
                    g: (0, _parseInt2.default)(m[2], 10),
                    b: (0, _parseInt2.default)(m[3], 10)
                  });
                  mappedCode = _this2.code_map[hex];
                }
              }

              if (mappedCode) {
                textValue += '\x03' + mappedCode;
                addToggle('\x03' + mappedCode);
              }
            }

            if ((0, _indexOf.default)(_context3 = attribs.style).call(_context3, 'bold') > -1) {
              textValue += '\x02';
              addToggle('\x02');
            }

            if ((0, _indexOf.default)(_context4 = attribs.style).call(_context4, 'italic') > -1) {
              textValue += '\x1d';
              addToggle('\x1d');
            }

            if ((0, _indexOf.default)(_context5 = attribs.style).call(_context5, 'underline') > -1) {
              textValue += '\x1f';
              addToggle('\x1f');
            } // Welcome to the IE/Edge sucks section, time to do crazy things
            // IE11 doesnt support document.execCommand('styleWithCSS')
            // so we have individual nodes instead, which are handled below

          } else if (attribs.color) {
            // IE likes to remove spaces from rgb(1, 2, 3)
            // it also likes converting rgb to hex
            var _mappedCode = _this2.code_map[attribs.color] || _this2.code_map[attribs.color.replace(/,/g, ', ')] || _this2.code_map[Colours.hex2rgb(attribs.color)];

            if (_mappedCode) {
              textValue += '\x03' + _mappedCode;
              addToggle('\x03' + _mappedCode);
            }
          } else if (name === 'strong') {
            textValue += '\x02';
            addToggle('\x02');
          } else if (name === 'em') {
            textValue += '\x1d';
            addToggle('\x1d');
          } else if (name === 'u') {
            textValue += '\x1f';
            addToggle('\x1f');
          } else if (name === 'div' || name === 'br') {
            // divs and breaks are both considered newlines. For each line we need to
            // close all current toggles and then reopen them for the next so that the
            // styles continue .
            textValue += getToggles();
            textValue += '\n';
            textValue += getToggles();
          }

          if (attribs.src && _this2.code_map[attribs.src]) {
            textValue += _this2.code_map[attribs.src];
          }
        },
        ontext: function ontext(text) {
          textValue += text;
        },
        onclosetag: function onclosetag(tagName) {
          textValue += getToggles();
          toggles.pop();
        }
      }, {
        decodeEntities: true
      });
      parser.write(source);
      parser.end(); // Firefox likes to add <br/> at the end (some times inside the span)
      // fix by filtering out any lines that contain no content

      return (0, _filter.default)(_context6 = textValue.split(/\r?\n/)).call(_context6, function (line) {
        return !!Misc.stripStyles(line);
      }).join('\n');
    },
    reset: function reset(rawHtml, shouldFocus) {
      this.$refs.editor.innerHTML = rawHtml || '';
      this.current_el_pos = 0;
      this.current_el = this.$refs.editor; // Firefox inserts a <br> on empty contenteditables after it's been reset. But that
      // fucks up the placeholder :empty CSS selector we use. So just remove it.

      var br = this.$refs.editor.querySelector('br');

      if (br) {
        br.parentNode.removeChild(br);
      }

      if (shouldFocus) {
        this.focus();

        if (this.default_colour) {
          this.setColour(this.default_colour.code, this.default_colour.colour);
        }
      }

      this.updateValueProps();
    },
    resetStyles: function resetStyles() {
      this.focus();
      document.execCommand('styleWithCSS', false, true);
      document.execCommand('selectAll', false, null);
      document.execCommand('removeFormat', false, null);
      this.default_colour = null;
    },
    setColour: function setColour(code, colour) {
      // If no current text selection, set this colour as the default colour for
      // future messages too
      var range = window.getSelection().getRangeAt(0);

      if (range && range.collapsed) {
        this.default_colour = {
          code: code,
          colour: colour
        };
      }

      this.focus();
      document.execCommand('styleWithCSS', false, true);
      document.execCommand('foreColor', false, colour);
      this.code_map[colour] = code;
      this.updateValueProps();
    },
    toggleBold: function toggleBold() {
      document.execCommand('bold', false, null);
      this.updateValueProps();
    },
    toggleItalic: function toggleItalic() {
      document.execCommand('italic', false, null);
      this.updateValueProps();
    },
    toggleUnderline: function toggleUnderline() {
      document.execCommand('underline', false, null);
      this.updateValueProps();
    },
    addImg: function addImg(code, url) {
      this.focus();
      var existingImages = (0, _toConsumableArray2.default)((0, _values.default)(_lodash.default).call(_lodash.default, this.$refs.editor.querySelectorAll('img')));
      document.execCommand('styleWithCSS', false, true);
      document.execCommand('insertImage', false, url);
      this.code_map[url] = code;
      var newImg = null;
      var images = (0, _toConsumableArray2.default)((0, _values.default)(_lodash.default).call(_lodash.default, this.$refs.editor.querySelectorAll('img'))); // Find image that has just been inserted

      (0, _forEach.default)(images).call(images, function (img) {
        if ((0, _indexOf.default)(existingImages).call(existingImages, img) === -1) {
          newImg = img;
        }
      }); // Find the position of this new image node

      var prevElCnt = 0;
      var el = newImg;

      while (el) {
        el = el.previousSibling;
        prevElCnt++;
      }

      this.current_el = this.$refs.editor;
      this.current_el_pos = prevElCnt;
      this.updateValueProps();
      this.focus();
    },
    // Insert some text at the current position
    insertText: function insertText(text) {
      this.focus();
      document.execCommand('insertText', false, text);
      this.updateValueProps();
      this.focus();
    },
    // Replace the word at the current position with another
    setCurrentWord: function setCurrentWord(text, keepPosition) {
      var el = this.current_el;
      var pos = this.current_el_pos;
      var val = el.textContent || '';
      var startVal = val.substr(0, pos);
      var space = (0, _lastIndexOf.default)(startVal).call(startVal, ' ');

      if (space === -1) {
        space = 0;
      } else {
        // include the space after the word
        space++;
      }

      var startPos = space;
      var endVal = val.substr(pos);
      space = (0, _indexOf.default)(endVal).call(endVal, ' ');
      if (space === -1) space = endVal.length;
      var endPos = space;
      el.textContent = startVal.substr(0, startPos) + text + endVal.substr(endPos); // Move the caret after the word

      var range = document.createRange();

      if (keepPosition) {
        range.setStart(el, pos);
        range.setEnd(el, pos);
      } else if (el.nodeType === 3) {
        // TEXT_NODE
        range.setStart(el, startPos + text.length);
        range.setEnd(el, startPos + text.length);
      } else {
        // el is another type of node, so setStart/End() counts in nodes instead
        // of text length
        range.setStart(el, 1);
        range.setEnd(el, 1);
      }

      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      this.updateValueProps();
    },
    getCurrentWord: function getCurrentWord() {
      var el = this.current_el;
      var pos = this.current_el_pos;
      var val = el.textContent;
      var startVal = val.substr(0, pos);
      var space = (0, _lastIndexOf.default)(startVal).call(startVal, ' ');

      if (space === -1) {
        space = 0;
      } else {
        // include the space after the word
        space++;
      }

      var startPos = space;
      space = (0, _indexOf.default)(val).call(val, ' ', startPos);
      if (space === -1) space = val.length;
      var endPos = space;
      return {
        word: val.substr(startPos, endPos),
        position: pos - startPos
      };
    },
    getCaretIdx: function getCaretIdx() {
      var position = 0;
      var selection = window.getSelection();

      if (selection.rangeCount !== 0) {
        var range = window.getSelection().getRangeAt(0);
        var caretRange = range.cloneRange();
        caretRange.selectNodeContents(this.$el);
        caretRange.setEnd(range.endContainer, range.endOffset);
        position = caretRange.toString().length;
      }

      return position;
    },
    getRawText: function getRawText() {
      return this.$refs.editor.innerText;
    },
    // Focus the editable div and move the caret to the end
    focus: function focus() {
      var selection = window.getSelection();
      var range = document.createRange();
      range.setStart(this.current_el || this.$refs.editor, this.current_el_pos || 0);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
});

exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["utils"]) window._kiwi_exports["components"]["utils"] = {};
window._kiwi_exports["components"]["utils"]["IrcInput"]
window._kiwi_exports.components.utils.IrcInput = exports.default ? exports.default : exports;
