"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _default = _vue.default.extend({
  data: function data() {
    return {
      sidebarOpen: false,
      // sidebarSection may contain: '', 'user', 'settings', 'nicklist', 'about', 'component'
      sidebarSection: '',
      sidebarUser: null,
      activeComponent: null,
      activeComponentProps: null
    };
  },
  computed: {
    isDrawn: function isDrawn() {
      return this.sidebarOpen && this.section() && this.$state.ui.app_width <= 769;
    },
    isOpen: function isOpen() {
      return this.sidebarOpen && this.section() && this.$state.ui.app_width > 769;
    }
  },
  created: function created() {
    var _this = this;

    this.listen(this.$state, 'sidebar.component', function (component, props) {
      _this.resetSidebarState();

      _this.sidebarOpen = !!component;
      _this.activeComponent = component;
      _this.activeComponentProps = props || {};
      _this.sidebarSection = component ? 'component' : '';
    }); // Allow forcing the sidebar open at startup

    this.$nextTick(function () {
      // nextTick is needed because app_width is 0 on created()
      var sidebarDefault = _this.$state.setting('sidebarDefault');

      if (sidebarDefault && _this.$state.ui.app_width > 769) {
        _this.sidebarOpen = true;
        _this.sidebarSection = sidebarDefault;
      }
    });
  },
  methods: {
    section: function section() {
      if (!this.sidebarOpen) {
        return '';
      }

      var section = this.sidebarSection;

      if (section === 'component') {
        return section;
      }

      var buffer = this.$state.getActiveBuffer();

      if (buffer.isQuery()) {
        // This is a query with only one possible sidebar dont change the current state
        // instead attempt to show the user, this allows channels to show their nicklist
        var user = this.$state.getUser(buffer.getNetwork().id, buffer.name);

        if (user) {
          this.sidebarUser = user;
          return 'user';
        }

        return '';
      } // The following code is for channels only


      if (!buffer.isChannel()) {
        return '';
      }

      if (section === 'user' && this.sidebarUser) {
        if (buffer.hasNick(this.sidebarUser.nick)) {
          return 'user';
        } // This was going to show a user that is not even present in the current channel
        // permantly switch back to nicklist so it does not jump back to user
        // when they switch to a channel with that user


        this.sidebarSection = 'nicklist';
        return this.sidebarSection;
      } else if (section === 'nicklist') {
        return 'nicklist';
      } else if (section === 'settings') {
        return 'settings';
      } else if (section === 'about') {
        return 'about';
      }

      return '';
    },
    resetSidebarState: function resetSidebarState() {
      this.sidebarOpen = false;
      this.sidebarSection = '';
      this.sidebarUser = null;
      this.activeComponent = null;
      this.activeComponentProps = null;
    },
    close: function close() {
      this.resetSidebarState();
    },
    showUser: function showUser(user) {
      this.resetSidebarState();
      this.sidebarOpen = true;
      this.sidebarUser = user;
      this.sidebarSection = 'user';
    },
    showNicklist: function showNicklist() {
      this.resetSidebarState();
      this.sidebarOpen = true;
      this.sidebarSection = 'nicklist';
    },
    showBufferSettings: function showBufferSettings() {
      this.resetSidebarState();
      this.sidebarOpen = true;
      this.sidebarSection = 'settings';
    },
    showAbout: function showAbout() {
      this.resetSidebarState();
      this.sidebarOpen = true;
      this.sidebarSection = 'about';
    },
    toggleUser: function toggleUser(user) {
      this.section() === 'user' ? this.close() : this.showUser(user);
    },
    toggleNicklist: function toggleNicklist() {
      this.section() === 'nicklist' ? this.close() : this.showNicklist();
    },
    toggleBufferSettings: function toggleBufferSettings() {
      this.section() === 'settings' ? this.close() : this.showBufferSettings();
    },
    toggleAbout: function toggleAbout() {
      this.section() === 'about' ? this.close() : this.showAbout();
    }
  }
});

exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["SidebarState"]
window._kiwi_exports.components.SidebarState = exports.default ? exports.default : exports;
